'use client'
import {useState} from "react";
import {Eye, EyeClosed} from "lucide-react";

function PasswordInput({name, label}: { name: string, label: string }) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="mb-4">
            <label className="mb-2.5 block font-medium text-black dark:text-white">
                {label}
            </label>
            <div className="relative">
                <input
                    name={name}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />

                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-4 top-4 text-gray-600 dark:text-gray-300 focus:outline-none"
                >
                    {showPassword ? <EyeClosed/> : <Eye/>}
                </button>
            </div>
        </div>
    );
}

export default PasswordInput;
